import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import 'core-js/features/set';
// import "core-js/features/set";

import React from 'react';
import ReactDOM from 'react-dom';
import { BASEURL } from './dependency/Config';
import {isSignedIn,getAccessToken,getRefreshToken,getloginFullDetails} from '../src/auth';
import axios from 'axios';
import queryString from 'query-string'
// import jwt_decode from 'jwt-decode';
import ls from 'local-storage';
import { confirmAlert } from 'react-confirm-alert'; 
import {LogoutNow} from './dependency/UtilityFunctions';
// import './assets/css/magnific-popup.css';
// import './assets/css/jquery-ui.css';
// import './assets/css/main.css';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';


axios.defaults.baseURL = BASEURL;
  // axios.defaults.headers.common['Authorization'] = '';
  // axios.defaults.headers.common['Authorization'] = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9lYmsubWVybG90ZWsuY29tXC9hcGlcL2xvZ2luIiwiaWF0IjoxNTgzNzU0ODk5LCJleHAiOjE1ODM3NTg0OTksIm5iZiI6MTU4Mzc1NDg5OSwianRpIjoiWXZtQnpUZFFka3RIeVJ3WiIsInN1YiI6MSwicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.3xBRGHaYTHUlUyPm7TF9195gQyrfupVh536XA8bhxJ0";

//   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  //  axios.defaults.headers.post['Content-Type'] = 'application/json';
   axios.defaults.headers.post['Accept'] = 'application/json';
  //  axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

      
//    Access-Control-Allow-Origin
  axios.interceptors.request.use(request => {
      console.log(request);
   
     return request;
  }, error => {
      console.log(error);
      return Promise.reject(error);
  });
  
  axios.interceptors.response.use(response => {
      console.log(response);
       return response;
  }, error => {

      // console.log(error);
      // return Promise.reject(error);
      if (401 === error.response.status) {
 
     confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>Your Session has Expired, You will be Logout now</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                LogoutNow();
                 onClose();
              }}
            >
              Yes, Logout!
            </button>
          </div>
        );
      }
    });
    } else {
        // console.log(error);Ç    
        return Promise.reject(error);
        // return error;
}
  });








ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
