import axios from "axios";
import qs from "qs";
import ls from "local-storage";

export const CheckFileSize = (file) => {
  if (file.size > 1000000) {
    return false;
  } else {
    return true;
  }
};
export const ShowCurrentDate = () => {
  let day = new Date().getDate();
  let month = new Date().getMonth() + 1;
  let year = new Date().getFullYear();
  let currentdate = null;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  return (currentdate = year + "-" + month + "-" + day);
  //  return currentdate =day+'/'+month+'/'+year;  ;
};
export const getFutureDate = (numberOfDaysToAdd) => {
  var now = new Date();
  now.setDate(now.getDate() + numberOfDaysToAdd);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }
  // var dateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
  var futuredate = year + "-" + month + "-" + day;
  // var futuredate = day+'/'+month+'/'+year;
  return futuredate;
};

export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item._date] = item;
    return obj;
  }, {});

export const LogoutNow = async () => {
  // const { history } = this.props;
  let response = await ls.remove("Loggedinstate");
  let response2 = await ls.remove("Loggedinstate");

  window.location.href = "#/landingPage";
  window.location.reload();
};
export const ReadableDate = (isoformat) => {
  var readable = new Date(isoformat); // When we pass the ISO format to the JS Date constructor, the return is "Fri Jul 04 2014 21:06:08 GMT-0400 (Eastern Daylight Time)"
  var m = readable.getMonth(); // returns 6 (note that this number is one less than the number of the month in isoformat)
  var d = readable.getDate(); // returns 15
  var y = readable.getFullYear(); // returns 2012

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // we get the text name of the month by using the value of m to find the corresponding month name
  var mlong = months[m];

  var fulldate = mlong + " " + d + ", " + y;
  return fulldate;
};

export const getfullname = () => {
  let ReturnedString = ls.get("Loggedinstate") || {};
  // console.log(ReturnedString)
  if (ReturnedString.isloggedin === true) {
    return ReturnedString.fullname;
  } else {
    return "";
  }
};

export const getuserdata = () => {
  let ReturnedString = ls.get("Loggedinstate") || {};
  console.log(ReturnedString);
  //  alert(JSON.stringify(ReturnedString))
  if (ReturnedString.isloggedin === true) {
    //  return ReturnedString.usertype;
    return ReturnedString;
  } else {
    return "";
  }
};
export const getuserType = () => {
  let ReturnedString = ls.get("Loggedinstate") || {};
  //  console.log(ReturnedString)
  //  alert(JSON.stringify(ReturnedString))
  if (ReturnedString.isloggedin === true) {
    return ReturnedString.usertype;
    //  return ReturnedString;
  } else {
    return "";
  }
};
export const getusershortProfile = () => {
  let ReturnedString = ls.get("Loggedinstate") || {};
  //  console.log(ReturnedString)
  //  alert(JSON.stringify(ReturnedString))
  if (ReturnedString.isloggedin === true) {
    let ShortProfile = ls.get("loggedinUserdetails") || {};
    console.log("ShortProfile");
    console.log(ShortProfile);
    console.log("ShortProfile");
    return ShortProfile;
  } else {
    return "";
  }
};
export const GetRefrenceNumber = (length = 8) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const GetNumberRefrence = (length = 8) => {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const ConvertToCurrency = (value) => {
  let newvalue = parseInt(value).toLocaleString();
  return newvalue;
};
export const formatAmount = (amount, toWholeNumber = false) => {
  if (toWholeNumber) {
    const value = Math.round(Number(amount));
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let formattedAmount = (+amount || 0).toFixed(2).toString();
  if (!formattedAmount.includes(".")) {
    formattedAmount = `${amount}.00`;
  }
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
